import { default as _91_46_46_46pageSlug_932G9EUUIRCEMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/[...pageSlug].vue?macro=true";
import { default as activateZsCpxHlELiMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/activate.vue?macro=true";
import { default as indexTCoPDitHiyMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93TujbDEm1AKMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as new05cfXKOC7cMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlistZvyxobDyGhMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/watchlist.vue?macro=true";
import { default as faqhfcfpmxBZFMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as indexE4RlDjmy5QMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue?macro=true";
import { default as infodEgrFTZtkEMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as index7epd68RbFlMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue?macro=true";
import { default as _91showGroupSlug_93uFgVx2JoOLMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programSGezxn1AQ0Meta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopUKEqZjrzgnMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_93J4crZSB0zZMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as indexDhWegkqhpqMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue?macro=true";
import { default as _91showSlug_93nGJOP7A0aGMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as vouchersRNzzWlNqMqMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93wIStp2n6oSMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexdLkaZkXy3mMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_93QDcS8UtKwzMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemasPv1HWAwIymMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinemas.vue?macro=true";
import { default as citiesheVl5k1MDoMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cities.vue?macro=true";
import { default as indexE14BHHaYevMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/index.vue?macro=true";
import { default as indexLJM7CdG0xqMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as _91movieSlug_93UrXExHGkkhMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexBuRTkR6xHqMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93QkdwT2AbW9Meta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresp5Vv8VKKUfMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexFNaPaqALI9Meta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/index.vue?macro=true";
import { default as indexM6VHVif6DwMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/person/[personSlug]/index.vue?macro=true";
import { default as _91personSlug_932NQJOaNKoUMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsF9dQYibnbvMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/persons.vue?macro=true";
import { default as searchgWdDw9psWrMeta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/search.vue?macro=true";
import { default as vouchersriftDZhJY3Meta } from "/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: _91citySlug_93QDcS8UtKwzMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93wIStp2n6oSMeta?.name,
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "",
    meta: indexE4RlDjmy5QMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: _91showGroupSlug_93uFgVx2JoOLMeta?.name,
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93nGJOP7A0aGMeta?.name,
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93QDcS8UtKwzMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93wIStp2n6oSMeta?.name,
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexE4RlDjmy5QMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: _91showGroupSlug_93uFgVx2JoOLMeta?.name,
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93nGJOP7A0aGMeta?.name,
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93QDcS8UtKwzMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93wIStp2n6oSMeta?.name,
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexE4RlDjmy5QMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: _91showGroupSlug_93uFgVx2JoOLMeta?.name,
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93nGJOP7A0aGMeta?.name,
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91movieSlug_93UrXExHGkkhMeta?.name,
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93UrXExHGkkhMeta?.name,
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93UrXExHGkkhMeta?.name,
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93QkdwT2AbW9Meta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93QkdwT2AbW9Meta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93QkdwT2AbW9Meta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: _91personSlug_932NQJOaNKoUMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_932NQJOaNKoUMeta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_932NQJOaNKoUMeta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-09-04_14-21-08_a258acc/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  }
]